import React, {useState, useEffect} from 'react'

import {PropTypes} from 'prop-types'
import queryString from 'query-string'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useLocation, withRouter} from 'react-router'

import {AuthTokenKey} from '../../../infra/config/LocalStorageKeys'
import {
  DoLogin,
  DoPasswordRecovery,
  DoSignUp,
  DoOnboarding
} from '../../../infra/requests/AuthRequests'
import {
  GetUserInfo,
  GetUserConfig
} from '../../../infra/requests/UserRequests'
import {userSave} from '../../../redux/data/user/UserActions'
import AlertService from '../../../shared/components/alert/AlertService'
import LoginForm from '../../../shared/components/forms/loginForm/LoginForm'
import OnboardingForm from '../../../shared/components/forms/onboardingForm/OnboardingForm'
import RecoveryForm from '../../../shared/components/forms/recoveryForm/RecoveryForm'
import SignUpForm from '../../../shared/components/forms/signUpForm/SignUpForm'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import SmallFooter from '../../../shared/components/small-footer/SmallFooter'
import {
  Aside,
  AsideWelcome,
  AsideText,
  AsideTitle,
  LoginAside,
  LoginDiv,
  LoginFormular,
  LoginLegend,
  LoginLink,
  LoginParagraph,
  LoginSpan,
  LoginWrapper,
  LogoContainer,
  SectionWrapper,
  Wrapper,
  SignUpDiv,
  SignUpWrapper,
  SignUpLink,
  LoginContent,
  LoginContentTitle,
  LoginContentSubtitle
} from './LoginStyles'

const Login = ({router, dispatch}) => {
  const [isClicked, setIsClicked] = useState(false)
  const [display, setDisplay] = useState(false)
  const [configuration, setConfiguration] = useState({})
  const [loading, setLoading] = useState(true)
  const [signUpDisplay, setSignUpDisplay] = useState(false)
  const [onboarding, setOnboarding] = useState(false)
  const [paramsEmail, setParamsEmail] = useState('')
  const [paramsMessageId, setParamsMessageId] = useState(0)

  const location = useLocation()

  useEffect(() => {
    const init = async () => {
      const {data, success} = await GetUserConfig(window.location.href)

      if (success) {
        setConfiguration(data)
      }

      const params = queryString.parse(location.search)

      if (params?.email !== undefined && params?.mid !== undefined) {
        setParamsEmail(params?.email)
        setParamsMessageId(params?.mid)
        setOnboarding(true)
      }

      setLoading(false)
    }

    init()
  }, [dispatch, location.search])

  const onSubmit = async (values) => {
    try {
      const result = await DoLogin(values)

      if (result.success) {
        localStorage.setItem(AuthTokenKey, result.data)
        const {data, success} = await GetUserInfo()

        if (success) {
          dispatch(userSave(data))
          const path = data.employeeId === 0 ? '/blank-page' : '/outfit'
          router.history.push(path)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const displaySwitch = () => {
    setDisplay(!display)
  }

  const onRecoverySubmit = async (values) => {
    try {
      const result = await DoPasswordRecovery(values)

      if (result.success) {
        AlertService.success(
          configuration?.defaultLanguage.languageId === 3
            ? 'Succès'
            : 'Success',
          configuration?.defaultLanguage.languageId === 3
            ? "Un nouveau mot de passe a été envoyé au courrier électronique de l'utilisateur"
            : "A new password was sent to the user's e-mail"
        )

        displaySwitch()
      } else {
        AlertService.error(
          configuration?.defaultLanguage.languageId === 3
            ? 'Erreur'
            : 'Error',
          configuration?.defaultLanguage.languageId === 3
            ? 'Erreur lors de la tentative de récupération de votre mot de passe, veuillez réessayer'
            : 'Error trying to recover your password, please try again'
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  function toggleSignUp() {
    setSignUpDisplay(!signUpDisplay)
  }

  const onSignUpSubmit = async (values) => {
    try {
      const result = await DoSignUp(values)

      if (result.success) {
        AlertService.success(
          configuration?.defaultLanguage.languageId === 3
            ? 'Succès'
            : 'Success',
          configuration?.defaultLanguage.languageId === 3
            ? "Un nouveau mot de passe a été envoyé au courrier électronique de l'utilisateur"
            : "A new password was sent to the user's e-mail"
        )

        toggleSignUp()
      } else {
        AlertService.error(
          configuration?.defaultLanguage.languageId === 3
            ? 'Erreur'
            : 'Error',
          configuration?.defaultLanguage.languageId === 3
            ? "L'e-mail choisi est déjà utilisé sur un autre utilisateur/employé"
            : 'The chosen e-mail is already used on another user/employee'
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
  function returnToLogin() {
    setOnboarding(false)
  }

  const onOnboardingSubmit = async (values) => {
    try {
      if (values?.password !== values?.passwordConfirm) {
        AlertService.error(
          configuration?.defaultLanguage.languageId === 3
            ? 'Erreur'
            : 'Error',
          configuration?.defaultLanguage.languageId === 3
            ? 'La confirmation du mot de passe ne correspond pas au mot de passe'
            : 'Password confirmation does not match the password'
        )
      } else {
        const result = await DoOnboarding({
          ...values,
          email: paramsEmail,
          messageId: paramsMessageId
        })

        if (result.success) {
          AlertService.success(
            configuration?.defaultLanguage.languageId === 3
              ? "Votre onboarding s'est déroulée avec succès !"
              : 'Your onboarding was successful!',
            configuration?.defaultLanguage.languageId === 3
              ? 'Veuillez maintenant vous connecter et passer votre commande de chaussures'
              : 'Now, please sign in and place your uniform order'
          )

          returnToLogin()
        } else {
          AlertService.error(
            configuration?.defaultLanguage.languageId === 3
              ? 'Erreur'
              : 'Error',
            configuration?.defaultLanguage.languageId === 3
              ? "Une erreur s'est produite lors de la tentative de finalisation, veuillez réessayer plus tard"
              : 'An error occurred while trying to complete, please try again later'
          )
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const clickedSwitch = () => {
    setIsClicked(!isClicked)
  }

  if (loading) {
    return <BaseLoading />
  }

  return (
    <>
      <SectionWrapper
        style={{
          backgroundColor: configuration?.layoutColors?.loginBackground
        }}
      >
        <LogoContainer>
          <LoginLink>
            {configuration?.logoWhite !== '' ? (
              <img
                src={configuration?.logoWhite}
                alt={configuration?.title}
                style={{width: '100%', maxWidth: '158px'}}
              />
            ) : null}
          </LoginLink>
        </LogoContainer>
        <Aside style={{backgroundImage: `url(${configuration?.banner})`}}>
          <Wrapper>
            <AsideWelcome>
              <Translate id='WELCOME_LOGIN' />
            </AsideWelcome>
            <AsideTitle>{configuration?.title}</AsideTitle>
            <AsideText>
              {configuration?.b2bclientId === 0 ? (
                <Translate id='WELCOME_SUB_DEFAULT' />
              ) : (
                <Translate id='WELCOME_SUB' />
              )}
            </AsideText>
          </Wrapper>
        </Aside>

        {onboarding === false ? (
          signUpDisplay === false ? (
            <LoginAside>
              <LoginWrapper
                style={{
                  display:
                    configuration?.functionalities?.enabledOnboarding ===
                    true
                      ? 'flex'
                      : 'block',
                  maxWidth:
                    configuration?.functionalities?.enabledOnboarding ===
                    true
                      ? '800px'
                      : '1271px'
                }}
              >
                <LoginFormular
                  style={{
                    maxWidth:
                      configuration?.functionalities?.enabledOnboarding ===
                      true
                        ? '384px'
                        : '100%'
                  }}
                >
                  <LoginLegend>
                    <LoginSpan>
                      <Translate id='SIGN_IN' />
                    </LoginSpan>
                  </LoginLegend>
                  <LoginForm
                    configuration={configuration}
                    setClicked={clickedSwitch}
                    setDisplay={displaySwitch}
                    isClicked={isClicked}
                    loginResponse={onSubmit}
                  />
                  <LoginDiv>
                    {display === true ? (
                      <>
                        <RecoveryForm
                          configuration={configuration}
                          setClicked={clickedSwitch}
                          setDisplay={displaySwitch}
                          isClicked={isClicked}
                          loginResponse={onRecoverySubmit}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </LoginDiv>
                  <LoginParagraph style={{margin: '0 0 10px'}} />
                </LoginFormular>

                {configuration?.functionalities?.enabledOnboarding ===
                true ? (
                  <SignUpDiv
                    style={{
                      maxWidth:
                        configuration?.functionalities
                          ?.enabledOnboarding === true
                          ? '384px'
                          : '100%'
                    }}
                  >
                    <LoginLegend>
                      <LoginSpan>
                        <Translate id='SIGN_UP' />
                      </LoginSpan>
                    </LoginLegend>
                    <SignUpWrapper>
                      <SignUpLink onClick={() => toggleSignUp()}>
                        <Translate id='SIGN_UP_LINK' />
                      </SignUpLink>
                    </SignUpWrapper>
                  </SignUpDiv>
                ) : (
                  <></>
                )}
              </LoginWrapper>
            </LoginAside>
          ) : (
            <LoginAside>
              <LoginWrapper
                style={{
                  display:
                    configuration?.functionalities?.enabledOnboarding ===
                    true
                      ? 'flex'
                      : 'block',
                  maxWidth:
                    configuration?.functionalities?.enabledOnboarding ===
                    true
                      ? '800px'
                      : '1271px'
                }}
              >
                <LoginFormular style={{maxWidth: '100%'}}>
                  <LoginLegend>
                    <LoginSpan>
                      <Translate id='SIGN_UP_LINK' />
                    </LoginSpan>
                  </LoginLegend>
                  <SignUpForm
                    configuration={configuration}
                    loginResponse={onSignUpSubmit}
                  />
                  <SignUpDiv
                    style={{maxWidth: '100%', margin: '16px 0 0'}}
                  >
                    <SignUpWrapper>
                      <SignUpLink onClick={() => toggleSignUp()}>
                        <Translate id='RETURN_TO_LOGIN_PAGE' />
                      </SignUpLink>
                    </SignUpWrapper>
                  </SignUpDiv>
                </LoginFormular>
              </LoginWrapper>
            </LoginAside>
          )
        ) : (
          <LoginAside>
            <LoginWrapper
              style={{
                display:
                  configuration?.functionalities?.enabledOnboarding ===
                  true
                    ? 'flex'
                    : 'block',
                maxWidth:
                  configuration?.functionalities?.enabledOnboarding ===
                  true
                    ? '800px'
                    : '1271px'
              }}
            >
              <LoginFormular style={{maxWidth: '100%'}}>
                <LoginLegend>
                  <LoginSpan>
                    <Translate id='ONBOARDING' />
                  </LoginSpan>
                </LoginLegend>
                <LoginContent>
                  <LoginContentTitle>
                    <Translate id='ONBOARDING_CONTENT_TITLE' />{' '}
                    {configuration?.title.replace('Portal ', '')}
                    {' - '}
                    <Translate id='ONBOARDING' />
                  </LoginContentTitle>
                  <LoginContentSubtitle>
                    <Translate id='ONBOARDING_CONTENT_SUBTITLE' />
                  </LoginContentSubtitle>
                </LoginContent>
                <OnboardingForm
                  configuration={configuration}
                  loginResponse={onOnboardingSubmit}
                />
                <SignUpDiv style={{maxWidth: '100%', margin: '16px 0 0'}}>
                  <SignUpWrapper>
                    <SignUpLink onClick={() => returnToLogin()}>
                      <Translate id='RETURN_TO_LOGIN_PAGE' />
                    </SignUpLink>
                  </SignUpWrapper>
                </SignUpDiv>
              </LoginFormular>
            </LoginWrapper>
          </LoginAside>
        )}
      </SectionWrapper>
      <SmallFooter />
    </>
  )
}

Login.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withRouter(connect()(Login))
